<template>
  <div>
    <div>
      <Loader></Loader>
      <div v-if="appData">
        <section id="home-section" class="home-section">
          <div class="hero-image">
            <div class="hero-inline-image">
              <img class="w-100 img-fluid home-image" src="./images/cover.png" alt="" />
            </div>
            <div class="hero-inline-content">
              <h3>COLLECTIVE INSURANCE BROKERS</h3>
              <h1>
                INSURANCE <br />
                PROPOSAL
              </h1>
            </div>
          </div>
          <div class="home-content">
            <div class="text-center mb-5">
              <h4>PREPARED BY: {{ appData.contactDetails.accountOwner }}</h4>
              <h4>
                RENEWAL DATE:
                {{
                appData.transaction.K_EffectiveDate
                | dateParse("YYYY-MM-DD")
                | dateFormat("DD/MM/YYYY")
                }}
              </h4>
            </div>
            <div class="text-center mb-13">
              <h2>
                <b>THE OWNERS {{ appData.asset.K_PlanNumber }}</b>
              </h2>
              <h2>
                {{ appData.asset.K_Address }}, {{ appData.asset.K_Suburb }}
                {{ appData.asset.K_State }}
                {{ appData.asset.K_Postcode }}
              </h2>
            </div>
            <div class="inline-flex-image">
              <div>
                <img class="img-fluid" src="./images/steadfast_logo.jpg" alt="" />
              </div>
              <div>
                <img class="img-fluid" src="./images/CIB_logo.png" alt="" />
              </div>
            </div>
          </div>
        </section>
        <header>
          <div id="header-img">
            <img class="w-100 header-img-height" src="./images/follower_sticky.png" alt="" />
          </div>
        </header>

        <!-- Sticky Buttons Start -->
        <div class="row justify-content-lg-end">
          <!-- Create PDF Button -->
          <button v-if="appData.transaction.K_BrokerAdviceStatus === 'Finalised'"
            style="bottom: 440px; right: 20px; z-index: 999;" class="btn btn-danger btn-circle position-fixed"
            @click.prevent="createPDF" v-b-tooltip.hover.topleft title="Create PDF">
            <span><i class="far fa-file-pdf fa-2x"></i></span>
          </button>

          <!-- Finalise Button -->
          <button v-if="
              appData.transaction.K_BrokerAdviceStatus ===
                'Ready For Finalisation' ||
              appData.transaction.K_BrokerAdviceStatus === 'Approved'
            " style="bottom: 440px; right: 20px; z-index: 999;" class="btn btn-success btn-circle position-fixed"
            @click.prevent="openFinaliseConfirmationDialog" v-b-tooltip.hover.topleft title="Finalise">
            <span><i class="fas fa-check fa-2x"></i></span>
          </button>

          <!-- Save Button -->
          <button v-if="
              appData.transaction.K_BrokerAdviceStatus === 'Draft' ||
              (appData.transaction.K_BrokerAdviceStatus === 'Under Review' &&
                isCurrentUserManager)
            " style="bottom: 380px; right: 20px; z-index: 999;" class="btn-circle btn-purple position-fixed"
            @click.prevent="saveData" v-b-tooltip.hover.topleft title="Save">
            <span><i class="fa fa-save fa-2x"></i></span>
          </button>

          <!-- Edit Button -->
          <button v-if="
              appData.transaction.K_BrokerAdviceStatus ===
                'Ready For Finalisation' ||
              appData.transaction.K_BrokerAdviceStatus === 'Approved' ||
              (appData.transaction.K_BrokerAdviceStatus === 'Awaiting Review' &&
                isCurrentUserManager)
            " style="bottom: 380px; right: 20px; z-index: 999;" class="btn btn-purple btn-circle position-fixed"
            @click.prevent="editData" v-b-tooltip.hover.topleft title="Edit">
            <span><i class="fa fa-pen fa-lg"></i></span>
          </button>

          <!-- Close Page Button -->
          <!--          <button-->
          <!--            style="bottom: 320px; right: 20px;" class="btn btn-secondary btn-circle position-fixed"-->
          <!--            @click.prevent="exit"-->
          <!--            v-b-tooltip.hover.topleft title="Close Page"-->
          <!--          >-->
          <!--            <span><i class="fas fa-times fa-2x"></i></span>-->
          <!--          </button>-->
        </div>
        <!-- Sticky Buttons End -->

        <section class="steadfast">
          <div class="container">
            <div class="row pb-4 main-content">
              <div class="col-4">
                <div class="side-nav">
                  <ul class="nav">
                    <li>
                      <a class="active scroll-test scroll-content" id="v-pills-home-tab" href="#v-pills-home">
                        Renewal Summary
                      </a>
                    </li>

                    <li>
                      <a class="scroll-content" id="v-pills-execute-summary-tab" href="#v-pills-execute-summary">
                        EXECUTIVE SUMMARY
                      </a>
                    </li>
                    <li>
                      <a id="v-pills-broker-reco-tab" class="scroll-content" href="#v-pills-broker-reco">
                        BROKER RECOMMENDATION
                      </a>
                    </li>
                    <li>
                      <a class="scroll-content" id="v-pills-quotation-schedule-tab" href="#v-pills-quotation-schedule">
                        QUOTATION SCHEDULE
                      </a>
                    </li>
                    <li v-if="showInsurerSpecialCondition">
                      <a class="scroll-content" id="v-pills-terms-condition-tab" href="#v-pills-terms-condition">
                        INSURER SPECIAL TERMS & CONDITIONS
                      </a>
                    </li>
                    <li>
                      <a class="scroll-content" id="v-pills-claims-history-tab" href="#v-pills-claims-history">
                        CLAIMS HISTORY - 5 Years
                      </a>
                    </li>
                    <li>
                      <a class="scroll-content" id="v-pills-insurance-market-tab" href="#v-pills-insurance-market">
                        INSURANCE MARKET UPDATE
                      </a>
                    </li>
                    <li>
                      <a id="v-pills-policy-reco-tab" class="scroll-content" href="#v-pills-policy-reco">
                        POLICY RECOMMENDATIONS
                      </a>
                    </li>
                    <li>
                      <a class="scroll-content" id="v-pills-insurer-product-tab" href="#v-pills-insurer-product">
                        INSURER PRODUCT DISCLOSURE STATEMENTS (PDS)
                      </a>
                    </li>
                    <li>
                      <a class="scroll-content" id="v-pills-important-notice-tab" href="#v-pills-important-notice">
                        IMPORTANT NOTICES
                      </a>
                    </li>
                    <li>
                      <a class="scroll-content" id="v-pills-duty-disclosure-tab" href="#v-pills-duty-disclosure">
                        DUTY OF DISCLOSURE
                      </a>
                    </li>
                    <li>
                      <a id="v-pills-pre-renewal-tab" class="scroll-content" href="#v-pills-pre-renewal">
                        PRE-RENEWAL DECLARATION
                      </a>
                    </li>
                    <li>
                      <a class="scroll-content" id="#v-pills-fire-service-tab" href="#v-pills-fire-service">
                        FIRE SERVICE LEVY
                      </a>
                    </li>
                    <li>
                      <a class="scroll-content" id="#v-pills-remuneration-tab" href="#v-pills-remuneration">
                        REMUNERATION
                      </a>
                    </li>
                    <li>
                      <a class="scroll-content" id="#v-pills-contacts-tab" href="#v-pills-contacts">
                        CONTACTS
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-8 pb-4" id="content">
                <summary-recommendation id="v-pills-home" :renewalSummary="appData.renewalSummary" />
                <executive-summary ref="executiveSummary" id="v-pills-execute-summary"
                  :executiveSummary="appData.executiveSummary" :strataCompany="appData.asset.K_StrataCompany.name"
                  :renewalSummary="appData.renewalSummary" :is-read-only="isReadOnly" />
                <broker-recommendation id="v-pills-broker-reco" :brokerRecommendation="appData.brokerRecommendation"
                  :is-read-only="isReadOnly" />

                <quotation-schedule id="v-pills-quotation-schedule" :quotationSchedule="appData.quotationSchedule" />

                <terms-and-conditions id="v-pills-terms-condition" :insurers="appData.quotationSchedule.insurers"
                  v-if="showInsurerSpecialCondition" />
                <claims-history id="v-pills-claims-history" :claimsHistory="appData.claims" />
                <!-- {{ appData }} -->
                <insurance-market-update id="v-pills-insurance-market" />
                <policy-recommendations id="v-pills-policy-reco" />
                <product-disclosure-statement :insurers="appData.quotationSchedule.insurers"
                  :buildingType="appData.asset.K_BuildingType" />
                <important-notices id="v-pills-important-notice" />
                <duty-of-disclosure class="mt-10" />
                <pre-renewal-declaration id="v-pills-pre-renewal" :declaration="appData.declaration"
                  :renewalDeclarationReceived="appData.transaction.renewalDeclarationReceived"
                  :declarationLink="appData.declarationFormLink"
                  :declarationFormReceived="appData.renewalDeclarationReceived" />
                <fire-service-levy class="mt-10" id="v-pills-fire-service" :fees="appData.fees" />
                <remuneration class="mt-10" id="v-pills-remuneration" :fees="appData.fees" />
                <contacts id="v-pills-contacts" :contactDetails="appData.contactDetails" />
                <div class="row contact-info my-5 collective_brokers">
                  <div class="col-lg-4">
                    <h6 class="mb-4 text-center">
                      CONTACTS AT COLLECTIVE INSURANCE BROKERS
                    </h6>
                    <div class="caller-info text-center my-5">
                      <div class="caller-img mx-auto mb-1">
                        <img class="img-fluid" src="./images/icons_contact_phone.png" alt="" />
                      </div>
                      <div class="caller-body">
                        <p class="mb-0">Telephone</p>
                        <p>02 8319 5670</p>
                      </div>
                    </div>
                    <div class="caller-info text-center my-5">
                      <div class="caller-img mx-auto mb-1">
                        <img class="img-fluid" src="./images/icons_contact_email.png" alt="" />
                      </div>
                      <div class="caller-body">
                        <p class="mb-0">Email</p>
                        <p>
                          <a class="text-black" href="mailto:info@collectiveib.com.au">
                            info@collectiveib.com.au</a>
                        </p>
                      </div>
                    </div>
                    <div class="caller-info text-center my-5">
                      <div class="caller-img mx-auto mb-1">
                        <img class="img-fluid" src="./images/icons_contact_web.png" alt="" />
                      </div>
                      <div class="caller-body">
                        <p class="mb-0">Web</p>
                        <a class="text-black" target="_blank" href="https://www.collectiveib.com.au">
                          www.collectiveib.com.au</a>
                      </div>
                    </div>
                    <div class="caller-info text-center my-5">
                      <div class="caller-img mx-auto mb-1">
                        <img class="img-fluid" src="./images/icons_contact_address.png" alt="" />
                      </div>
                      <div class="caller-body">
                        <p class="mb-0">Address</p>
                        <p>
                          Suite 602, <br />
                          447 Kent Street, <br />
                          Sydney NSW 2000
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="container">
                  <div class="float-left">
                    <img class="h-100px mb-20" src="./images/steadfast_logo.jpg" alt="Steadfast" />
                  </div>
                  <div class="float-right">
                    <img class="img-fluid h-100px mb-20" src="./images/CIB_logo.png"
                      alt="Collective Insurance Brokers" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Loader from "@/view/layout/loader/Loader.vue";
import BrokerAdviceDataService from "./broker.advice.data.service";
import SummaryRecommendation from "./pages/SummaryRecommendation.vue";
import BrokerRecommendation from "./pages/BrokerRecommendation.vue";
import ClaimsHistory from "./pages/ClaimsHistory.vue";
import Contacts from "./pages/Contacts.vue";
import DutyOfDisclosure from "./pages/DutyOfDisclosure.vue";
import ExecutiveSummary from "./pages/ExecutiveSummary.vue";
import FireServiceLevy from "./pages/FireServiceLevy.vue";
import ImportantNotices from "./pages/ImportantNotices.vue";
import InsuranceMarketUpdate from "./pages/InsuranceMarketUpdate.vue";
import PolicyRecommendations from "./pages/PolicyRecommendations.vue";
import PreRenewalDeclaration from "./pages/PreRenewalDeclaration.vue";
import ProductDisclosureStatement from "./pages/ProductDisclosureStatement.vue";
import QuotationSchedule from "./pages/QuotationSchedule.vue";
import Remuneration from "./pages/Remuneration.vue";
import TermsAndConditions from "./pages/TermsAndConditions.vue";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

export default {
  components: {
    Loader,
    SummaryRecommendation,
    BrokerRecommendation,
    ClaimsHistory,
    Contacts,
    DutyOfDisclosure,
    ExecutiveSummary,
    FireServiceLevy,
    ImportantNotices,
    InsuranceMarketUpdate,
    PolicyRecommendations,
    PreRenewalDeclaration,
    ProductDisclosureStatement,
    TermsAndConditions,
    QuotationSchedule,
    Remuneration,
  },

  name: "RenewalBrokerAdvice",

  data() {
    return {
      appData: null,
      isCurrentUserManager: false,
      showInsurerSpecialCondition: false,
    };
  },

  mounted() {
    // Extract information from URL params
    let queryParams = this.$route.query;
    if (!queryParams.transactionId) {
      console.log("Unauthorised access!");
      // TODO Handle error
      return;
    }

    // Initialise RenewalDataService with the token
    BrokerAdviceDataService.init(
      queryParams.transactionId,
      queryParams.userId,
      queryParams.token
    );

    // Check user privileges
    this.checkUser(queryParams.userId);

    BrokerAdviceDataService.getBrokerAdviceData()
      .then(({ data }) => {
        this.appData = data.result;

        if (this.appData.quotationSchedule && this.appData.quotationSchedule.insurers) {
          // Slice the insurers array to keep only the first 3 elements
          this.appData.quotationSchedule.insurers = this.appData.quotationSchedule.insurers.slice(0, 3);
        }

        this.$nextTick(() => {
          this.appData.quotationSchedule.insurers.forEach((x) => {
            if (x.conditions) {
              this.showInsurerSpecialCondition = true;
            }
          });

          // Set main page and its component height and width
          document.querySelector(".home-image").style.height =
            window.screen.height - 500 + "px";
          document.querySelector(".home-section").style.height =
            window.screen.height + "px";
          document.querySelector("#content").style.height =
            window.screen.height + "px";

          // Fetch all left side menu node
          let nodes = document.querySelectorAll(".scroll-content");
          for (let i = 0; i < nodes.length; i++) {
            let b = nodes[i];
            document.getElementById(b.getAttribute("id")).onclick = function (
              event
            ) {
              event.preventDefault();
              // Add active class in left side menu : when clicked on menu
              document.querySelectorAll(".nav a").forEach(function (x) {
                document
                  .getElementById(x.getAttribute("id"))
                  .classList.remove("active");
              });

              const yOffset =
                -document.getElementById("header-img")?.offsetHeight || 200;
              const element = document.querySelector(
                "#" + event.target.getAttribute("href").slice(1)
              );
              const y =
                element.getBoundingClientRect().top +
                window.pageYOffset +
                yOffset;

              window.scrollTo({ top: y, behavior: "smooth" });
              // added active class on click element
              document
                .getElementById(b.getAttribute("id"))
                .classList.add("active");
            };
          }
        });
      })
      .catch((error) => {
        // TODO Handle error
        console.log(error);
      });
  },

  created() {
    document.addEventListener("scroll", this.handleScroll);
  },

  computed: {
    isReadOnly: function () {
      if (this.appData && this.appData.transaction) {
        let currentStatus = this.appData.transaction.K_BrokerAdviceStatus;
        if (this.isCurrentUserManager) {
          return currentStatus !== "Draft" && currentStatus !== "Under Review";
        } else {
          return currentStatus !== "Draft";
        }
      }
      return true;
    },
  },

  methods: {
    checkUser: function (userId) {
      if (userId) {
        BrokerAdviceDataService.isManagerReviewer(userId)
          .then(({ data }) => {
            this.isCurrentUserManager = data.result || false;
          })
          .catch((error) => {
            // TODO Handle error
            console.log(error);
          });
      }
    },

    editData: function () {
      let currentStatus = this.appData.transaction.K_BrokerAdviceStatus;
      if (currentStatus === "Ready For Finalisation") {
        this.appData.transaction.K_BrokerAdviceStatus = "Draft";
      } else if (
        currentStatus === "Awaiting Review" &&
        this.isCurrentUserManager
      ) {
        this.appData.transaction.K_BrokerAdviceStatus = "Under Review";
      }

      this.startPageLoading();
      BrokerAdviceDataService.saveBrokerAdviceData(this.appData)
        .then(() => {
          this.stopPageLoading();
        })
        .catch((error) => {
          // TODO Handle error
          console.log(error);
          this.stopPageLoading();
        });
    },

    saveData: function () {
      let currentStatus = this.appData.transaction.K_BrokerAdviceStatus;
      if (currentStatus === "Draft") {
        this.appData.transaction.K_BrokerAdviceStatus =
          "Ready For Finalisation";
      } else if (
        currentStatus === "Under Review" &&
        this.isCurrentUserManager
      ) {
        this.appData.transaction.K_BrokerAdviceStatus = "Awaiting Review";
      }

      this.startPageLoading();
      BrokerAdviceDataService.saveBrokerAdviceData(this.appData)
        .then(() => {
          this.stopPageLoading();
        })
        .catch((error) => {
          // TODO Handle error
          console.log(error);
          this.stopPageLoading();
        });
    },

    openFinaliseConfirmationDialog: function () {
      Swal.fire({
        title: "Confirm Finalisation",
        html: `<p class="text-justify">Are you sure you wish to finalise this Broker Advice?</p><br/>
               <p class="text-justify">Please note that the Broker Advice <strong>cannot be modified</strong> after this action.</p>`,
        icon: "info",
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false,
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: "Confirm",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.finaliseBrokerAdvice();
        }
      });
    },

    finaliseBrokerAdvice: function () {
      this.appData.transaction.K_BrokerAdviceStatus = "Finalised";

      this.startPageLoading();
      BrokerAdviceDataService.saveBrokerAdviceData(this.appData)
        .then(() => {
          this.stopPageLoading();
        })
        .catch((error) => {
          // TODO Handle error
          console.log(error);
          this.stopPageLoading();
        });
    },

    createPDF: function () {
      this.startPageLoading();

      BrokerAdviceDataService.createBrokerAdvicePDF()
        .then(({ data }) => {
          this.stopPageLoading();

          // Open PDF in new browser tab
          const base64 = data.result;
          const blob = base64ToBlob(base64, "application/pdf");
          const url = URL.createObjectURL(blob);
          const pdfWindow = window.open("");
          pdfWindow.document.write(`
        <!DOCTYPE html>
        <html lang="en">
        <head>
          <title>Broker Advice PDF</title>
          <style>html, body { height: 100%; margin: 0; padding: 0; overflow: hidden; } iframe { width: 100%; height: 100%; border: none; }</style>
        </head>
        <body>
          <iframe src="${url}" frameborder="0"></iframe>
        </body>
        </html>
      `);

          // Close the document. Important for some browsers.
          pdfWindow.document.close();

          function base64ToBlob(base64, type = "application/octet-stream") {
            const binStr = atob(base64);
            const len = binStr.length;
            const arr = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
              arr[i] = binStr.charCodeAt(i);
            }
            return new Blob([arr], { type: type });
          }
        })
        .catch((error) => {
          // TODO Handle error
          console.log(error);
          this.stopPageLoading();
        });
    },

    exit: function () {
      window.close();
    },

    handleScroll: async function () {
      // get Header height for offset
      const topHeader =
        (document.getElementById("header-img")?.offsetHeight || 200) + 50;
      // fetch current position
      let pos = document.querySelector(".steadfast").getBoundingClientRect();
      let el = document.querySelector("#header-img");
      // set header img and it's height
      if (pos.top < 270) {
        el.classList.add("header-img");
        document.querySelector(".side-nav").classList.add("side-nav-fixed");
      } else {
        el.classList.remove("header-img");
        document.querySelector(".side-nav").classList.remove("side-nav-fixed");
      }

      // remove class
      document.querySelectorAll(".nav a").forEach(function (x) {
        document
          .getElementById(x.getAttribute("id"))
          .classList.remove("active");
      });
      let status = true;
      // handle scroll event
      document.querySelectorAll(".nav a").forEach(function (x) {
        let refElementPosition = document
          .getElementById(x.getAttribute("href").slice(1))
          ?.getBoundingClientRect();

        //check section is currently displayed or not
        if (status) {
          if (
            refElementPosition.top + refElementPosition.height > topHeader &&
            refElementPosition.top < window.screen.height - topHeader
          ) {
            document
              .getElementById(x.getAttribute("id"))
              .classList.add("active");
            status = false;
          }
        }
      });
    },

    startPageLoading: function () {
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
    },

    stopPageLoading: function () {
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    },
  },
};
</script>
<style>
@import "./css/bootstrap.css";
@import "./css/custom.css";

.page-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
